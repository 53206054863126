<template>
	<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
        <div class="navbar-wrapper">
            <div class="navbar-container content">
                <div class="navbar-collapse" id="navbar-mobile">
                    <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <ul class="nav navbar-nav">
                            <li class="nav-item mobile-menu d-lg-none mr-auto">
                                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>
                        </ul>
                    </div>   
                    <ul class="nav navbar-nav float-right">
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <div class="user-nav d-sm-flex d-none ml-1 text-right">
                                    <span class="text-primary font-600 f-16">{{account.admin.fullName}}</span>
                                    <span class="text-right text-black f-12">{{account.admin.role.name}}</span>
                                </div>
                                <span>
                                    <img class="round" :src="account.admin.avatar ? account.admin.avatar:'/assets/img/icon/profile.png'" alt="avatar" height="40" width="40">
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <section class="d-lg-none">
                                    <div class="col" style="margin-top: 5px;">
                                        <p class="text-primary font-600 f-16 mb-0">{{account.admin.fullName}}</p>
                                        <p class="text-black f-12 mb-0">{{account.admin.role.name}}</p>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                </section>
                                <router-link to="/user/edit_profile" tag="a" class="dropdown-item">
                                    <i class="feather icon-user"></i> Edit Profile
                                </router-link>
                                <router-link to="/user/change_password" tag="a" class="dropdown-item">
                                    <i class="feather icon-lock"></i> Change Password
                                </router-link>
                                <div class="dropdown-divider"></div>
                                <a href="javascript:;" @click="logout()" class="dropdown-item">
                                	<i class="feather icon-power"></i> Logout
                                </a>
                            </div>
                        </li>
                    </ul>                 
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import $ from 'jquery'

export default {
    data() {
        return {
            account: JSON.parse(localStorage.getItem('account'))
        }
    },
	methods: {
        logout() {
            let _ = this
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			_.$store.dispatch('logout')
			window.location = '/login'
        }
	},
    mounted() {
        let _ = this
        _.$root.$on('changeProfile', (data) => {
            _.account = data
            localStorage.setItem('account', JSON.stringify(_.account))
        })
    },
}
</script>