<template>
	<footer class="footer footer-static footer-light">
        <p class="clearfix blue-grey lighten-2 mb-0 f-14">
            <span class="float-md-left d-block d-md-inline-block mt-25">Copyright &copy; 2021 <a class="text-bold-800 grey darken-2" href="#" target="_blank">Diri Clinic</a>, All Rights Reserved.</span>
            <button class="btn btn-primary btn-icon scroll-top" type="button"><i class="feather icon-arrow-up"></i></button>
        </p>
    </footer>
</template>

<script>
export default {
	name: 'Footer'
}
</script>